<template>
    <div class="login-wrap">
        <!-- <div class="topTitleBox">
            <div class="topTitle">
                <div class="topTitle-L">{{unionLoginParams.name}}平台</div>
                <div class="topTitle-R">
                    <span @click="setCor(index + 1)" v-for="(item, index) in topTitleList" :key="index" :class="{ setTopTitleSpan: index + 1 == topIndex }">{{ item }}</span>
                </div>
            </div>
        </div> -->
        <!-- <div class="banner" id="corBox1" ref="setTimeRef">
            <div class="bannerMo"></div>
            <div class="banner-body">
                <div class="banner-body-l">
                    <div class="banner-body-l-top">{{ unionLoginParams.name }}</div>

                    <div class="banner-body-l-but">
                        <el-button type="primary" round class="body-l-but" @click="toAdminLogin">登录后台</el-button>
                    </div>
                </div>
                <div class="banner-body-r"></div>
            </div>
        </div> -->

        <div class="loginBox">
            <div class="boxTitle">上海(浦东)供销市集交易平台</div>
            <el-form :model="param" :rules="rules" ref="login" label-width="50px" class="ms-content">
                <el-form-item prop="phone" label="账号">
                    <el-input
                        v-model="param.phone"
                        placeholder="手机号"
                        class="SetInput"
                        style="width: 100%"
                        suffix-icon="el-icon-lx-people"
                    >
                    </el-input>
                </el-form-item>

                <el-form-item prop="password" label="密码">
                    <el-input
                        type="password"
                        placeholder="password"
                        v-model="param.password"
                        @keyup.enter.native="submitForm()"
                        class="SetInput"
                        suffix-icon="el-icon-lx-lock"
                    >
                    </el-input>
                </el-form-item>
                <div class="login-btn">
                    <el-row>
                        <el-col :span="24">
                            <el-button type="primary" @click="submitForm()" :loading="loginLoading">登录</el-button>
                        </el-col>
                    </el-row>
                </div>
            </el-form>
        </div>

        <!-- <el-dialog class="setDiaLog" :visible.sync="loginAdminShow" width="30%" :before-close="handleClose">
            <div class="dialogBox">
                <div class="dialogBox-title">{{ title }}管理系统</div>
                <el-form :model="param" :rules="rules" ref="login" label-width="0px" class="ms-content">
                    <el-form-item prop="phone">
                        <el-input v-model="param.phone" placeholder="手机号" class="SetInput" style="width: 100%">
                            <el-button slot="prepend" icon="el-icon-lx-people"></el-button>
                        </el-input>
                    </el-form-item>

                    <el-form-item prop="password">
                        <el-input
                            type="password"
                            placeholder="password"
                            v-model="param.password"
                            @keyup.enter.native="submitForm()"
                            class="SetInput"
                        >
                            <el-button slot="prepend" icon="el-icon-lx-lock"></el-button>
                        </el-input>
                    </el-form-item>
                    <div class="login-btn">
                        <el-row>
                            <el-col :span="24">
                                <el-button type="primary" @click="submitForm()" :loading="loginLoading">登录</el-button>
                            </el-col>
                        </el-row>
                    </div>
                </el-form>
            </div>
        </el-dialog> -->

        <!-- 登录 
        <el-dialog title="" :visible.sync="loginShow" width="30%" :before-close="handleClose" class="setDiaLog">
            <div class="dialogBox">
                <div class="dialogBox-title">分销商登录</div>

                <el-form :model="param" :rules="rules" ref="login" label-width="0px" class="ms-content">
                    <el-form-item prop="phone">
                        <el-input v-model="param.phone" class="SetInput" type="text" auto-complete="off" placeholder="请输入手机号">
                            <countryCodeSelector slot="prepend" :value.sync="value"> </countryCodeSelector>
                        </el-input>
                    </el-form-item>

                    <el-form-item style="border: 1px solid #dcdfe6; border-radius: 4px; margin-top: 30px" prop="password">
                        <el-input
                            id="pass"
                            autocomplete="off"
                            type="password"
                            class="input"
                            v-model="param.password"
                            placeholder="请输入密码"
                        >
                        </el-input>
                        <el-button type="text">
                            <span style="color: #888">|</span
                            ><span style="margin-left: 30px; color: #007aff" @click="toRese">忘记密码？</span>
                        </el-button>
                    </el-form-item>

                    <div class="login-btn">
                        <el-button :loading="loginLoading" type="primary" @click="submitForm()">登录后台</el-button>
                    </div>

                    <div class="login-tips">没有分销商账号？<span @click="toRegister">去注册</span></div>
                </el-form>
            </div>
            <div class="loginLBox">
                <div class="loginLBox-item">
                    <div class="loginLBox-item-icon"></div>
                    <div class="loginLBox-item-icon1"></div>
                    <div class="loginLBox-item-icon2"></div>
                </div>
            </div>
            <img class="setLoginImg" src="../assets/images/login-tips.png" alt="" />
        </el-dialog>

        注册 
        <el-dialog title="" :visible.sync="registerShow" width="30%" :before-close="registerClose" class="setDiaLog">
            <div class="dialogBox">
                <div class="dialogBox-title">注册分销商</div>

                <el-form :model="registerForm" :rules="registerRules" ref="registerForm" label-width="0px" class="ms-content">
                    <el-form-item prop="phone">
                        <el-input v-model="registerForm.phone" class="SetInput" type="text" auto-complete="off" placeholder="请输入手机号">
                            <countryCodeSelector slot="prepend" :value.sync="value"> </countryCodeSelector>
                            
                        </el-input>
                    </el-form-item>

                    <el-form-item style="border: 1px solid #dcdfe6; border-radius: 4px; margin-top: 30px" prop="smsCode">
                        <el-input
                            id="pass"
                            autocomplete="off"
                            type="text"
                            class="input"
                            v-model="registerForm.smsCode"
                            placeholder="请输入验证码"
                        >
                        </el-input>
                        <el-button type="text">
                            <span style="color: #888">|</span
                            ><span style="margin-left: 20px; color: #007aff" @click="sendCode">{{ btnText }}</span>
                        </el-button>
                    </el-form-item>

                    <el-form-item prop="username">
                        <el-input placeholder="请输入您的分销商名称" class="SetInput" v-model="registerForm.username"> </el-input>
                    </el-form-item>

                    <el-form-item prop="type">
                        <el-radio-group v-model="registerForm.entity_type">
                            <el-radio :label="2">公司</el-radio>
                            <el-radio :label="1">个人</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <div class="login-btn">
                        <el-button type="primary" @click="subRegisFrom()">注册</el-button>
                    </div>

                    <div class="login-tips">已有分销商账号？<span @click="toLogin">去登录</span></div>
                </el-form>
            </div>
            <div class="loginLBox">
                <div class="loginLBox-item">
                    <div class="loginLBox-item-icon"></div>
                    <div class="loginLBox-item-icon1"></div>
                    <div class="loginLBox-item-icon2"></div>
                </div>
            </div>
            <img class="setLoginImg" src="../assets/images/login-tips.png" alt="" />
        </el-dialog>

        重置密码 
        <el-dialog title="" :visible.sync="resettingShow" width="30%" :before-close="resettingClose" class="setDiaLog">
            <div class="dialogBox">
                <div class="dialogBox-title">重置密码</div>

                <el-form :model="resettingFrom" :rules="resettingRules" ref="resetForm" label-width="0px" class="ms-content">
                    <el-form-item prop="phone">
                        <el-input v-model="resettingFrom.phone" class="SetInput" type="text" auto-complete="off" placeholder="请输入手机号">
                            <countryCodeSelector slot="prepend" :value.sync="value"> </countryCodeSelector>
                            
                        </el-input>
                    </el-form-item>

                    <el-form-item style="border: 1px solid #dcdfe6; border-radius: 4px; margin-top: 30px" prop="smsCode">
                        <el-input
                            id="pass"
                            autocomplete="off"
                            type="text"
                            class="input"
                            v-model="resettingFrom.smsCode"
                            placeholder="请输入验证码"
                        >
                        </el-input>
                        <el-button type="text">
                            <span style="color: #888">|</span
                            ><span style="margin-left: 20px; color: #007aff" @click="sendCodeReset">{{ resetBtnText }}</span>
                        </el-button>
                    </el-form-item>

                    <el-form-item prop="password">
                        <el-input placeholder="输入新密码" class="SetInput" v-model="resettingFrom.password"> </el-input>
                    </el-form-item>
                    <el-form-item prop="confirmPassword">
                        <el-input placeholder="确认密码" class="SetInput" v-model="resettingFrom.confirmPassword"> </el-input>
                    </el-form-item>
                    <div class="login-btn">
                        <el-button type="primary" @click="submitResetFrom()">重置密码</el-button>
                    </div>

                    <div class="login-tips">没有分销商账号？<span @click="toRegister">去注册</span></div>
                </el-form>
            </div>
            <div class="loginLBox">
                <div class="loginLBox-item">
                    <div class="loginLBox-item-icon"></div>
                    <div class="loginLBox-item-icon1"></div>
                    <div class="loginLBox-item-icon2"></div>
                </div>
            </div>
            <img class="setLoginImg" src="../assets/images/login-tips.png" alt="" />
        </el-dialog> -->
    </div>
</template>
<script>
import { request } from '@/common/request';
import countryCodeSelector from '@/components/country-selector.vue';

const Layout = () => import('../components/Home.vue');
export default {
    components: {
        countryCodeSelector
    },
    // type:"",
    data: function () {
        //密码验证
        var validatePass2 = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.resetFrom.password) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        return {
            param: {
                phone: '',
                password: '',
                platform: 'admin'
            },
            // 登录 表单
            unionLoginParams: {
                name: '',
                adminLoginConfig: {
                    contactInfo: {
                        phone: '',
                        wechat: '',
                        email: '',
                        qq: '',
                        wechatOfficeQrcode: '',
                        wechatServiceQrcode: ''
                    },

                    copyright: {
                        copyrightName: '',
                        copyrightTel: '',
                        copyrightAddress: ''
                    },
                    icpInfo: {
                        year: '',
                        icpName: '',
                        icpCode: ''
                    }
                }
            },
            registerForm: {
                phone: '',
                smsCode: '',
                username: '',
                entity_type: 2
            },

            resettingFrom: {
                // 重置密码表单
                phone: '', // 手机号
                password: '', // 密码
                confirmPassword: '', // 重置密码
                smsCode: '' // 短信验证码
            },

            value: '86', // 手机地区

            logDing: false,

            loginShow: false, // 登录弹窗状态
            loginAdminShow: false, // 后台登录弹窗状态
            registerShow: false, // 注册弹窗状态
            resettingShow: false, // 重置密码

            loginLoading: false, // 登录按钮状态
            topIndex: 1, // 用于高光顶部按钮
            setSolShow: false,
            topTitleList: ['首页', '我们的优势', '推广合作', '联系我们'],
            btnText: '发送验证码',
            sendCodeDisabled: false,
            time: 60,

            resetBtnText: '发送验证码',
            sendCodeDisabledReset: false,
            timeReset: 60,
            showFormGroup: 'navGroup', //btnGroup  | loginForm   |   registerForm
            title: '',
            rules: {
                phone: [
                    {
                        required: true,
                        message: '请输入手机号码',
                        trigger: 'blur'
                    },
                    {
                        min: 11,
                        max: 11,
                        message: '请输入11位手机号码',
                        trigger: 'blur'
                    },
                    {
                        pattern: /^1[0-9]{10}$/,
                        message: '请输入正确的手机号码'
                    }
                ],
                password: [
                    {
                        required: true,
                        message: '请输入密码',
                        trigger: 'blur'
                    }
                ]
            },
            registerRules: {
                phone: [
                    {
                        required: true,
                        message: '请输入手机号码',
                        trigger: 'blur'
                    },
                    {
                        min: 11,
                        max: 11,
                        message: '请输入11位手机号码',
                        trigger: 'blur'
                    },
                    {
                        pattern: /^1[0-9]{10}$/,
                        message: '请输入正确的手机号码'
                    }
                ],
                smsCode: [
                    {
                        required: true,
                        message: '请输入短信验证码',
                        trigger: 'blur'
                    }
                ],

                username: [
                    {
                        required: true,
                        message: '请输入分销商名称',
                        trigger: 'blur'
                    }
                ],

                type: [
                    {
                        required: true,
                        message: '请选择',
                        trigger: 'change'
                    }
                ]
            },

            resettingRules: {
                phone: [
                    {
                        required: true,
                        message: '请输入手机号码',
                        trigger: 'blur'
                    },
                    {
                        min: 11,
                        max: 11,
                        message: '请输入11位手机号码',
                        trigger: 'blur'
                    },
                    {
                        pattern: /^1[0-9]{10}$/,
                        message: '请输入正确的手机号码'
                    }
                ],
                smsCode: [
                    {
                        required: true,
                        message: '请输入短信验证码',
                        trigger: 'blur'
                    }
                ],
                password: [
                    {
                        required: true,
                        message: '请输入新密码'
                    }
                ],
                confirmPassword: [
                    {
                        required: true,
                        validator: validatePass2,
                        trigger: 'blur'
                    }
                ]
            }
        };
    },
    created() {
        this.topIndex = sessionStorage.getItem('topIndex') ? sessionStorage.getItem('topIndex') : 1;
        this.getUnionByDomain();
    },

    mounted() {
        window.addEventListener('scroll', this.handleScrollBox, true);
    },

    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScrollBox, true);
    },

    methods: {
        // 登录方法
        submitForm() {
            let that = this;
            this.logDing = true;
            this.$refs.login.validate((valid) => {
                if (valid) {
                    this.loginLoading = true;
                    request
                        .post('/login', this.param)
                        .then((ret) => {
                            console.log(that);
                            if (ret.code === 1) {
                                that.$message.success('登录成功');

                                localStorage.setItem('token', ret.data.token);
                                let user = JSON.stringify(ret.data.user);
                                localStorage.setItem('user', user);
                                this.getAdminRouter(true);
                                this.loginLoading = false;
                            } else {
                                this.loginLoading = false;
                                that.$message.error(ret.msg);
                            }
                            this.logDing = false;
                        })
                        .catch((err) => {
                            this.loginLoading = false;
                            this.logDing = false;
                        });
                } else {
                    this.$message.error('请输入账号和密码');
                    return false;
                }
            });
        },

        // 注册方法
        subRegisFrom() {
            let that = this;
            this.$refs.registerForm.validate((valid) => {
                if (valid) {
                    request
                        .post('/distributor/register', this.registerForm)
                        .then((ret) => {
                            console.log(that);
                            if (ret.code === 1) {
                                that.$message.success('登录成功');

                                localStorage.setItem('token', ret.data.token);
                                let user = JSON.stringify(ret.data.user);
                                localStorage.setItem('user', user);
                                this.getAdminRouter(true);
                                this.loginLoading = false;
                            } else {
                                this.loginLoading = false;
                                that.$message.error(ret.msg);
                            }
                        })
                        .catch((err) => {
                            this.loginLoading = false;
                        });
                } else {
                    // this.$message.error('请输入账号和密码');
                    return false;
                }
            });
        },

        // 重置密码方法
        submitResetFrom() {
            let that = this;
            this.$refs.resetForm.validate((valid) => {
                if (valid) {
                } else {
                    // this.$message.error('请输入账号和密码');
                    return false;
                }
            });
        },

        toBeian() {
            window.open('https://beian.miit.gov.cn/');
        },

        handleClose() {
            this.param = {
                phone: '',
                password: '',
                platform: 'admin'
            };
            this.loginShow = false;
            this.loginAdminShow = false;
        },

        // 打开登录弹窗
        toLogin() {
            this.param = {
                phone: '',
                password: '',
                platform: 'admin'
            };
            this.loginShow = true;
            this.registerShow = false;
        },
        // 打开登录弹窗
        toAdminLogin() {
            this.param = {
                phone: '',
                password: '',
                platform: 'admin'
            };
            this.loginAdminShow = true;
            //this.loginShow = false;
            this.registerShow = false;
        },

        // 打开注册弹窗
        toRegister() {
            this.registerForm = {
                phone: '',
                smsCode: '',
                username: '',
                type: 2
            };
            this.registerShow = true;
            this.resettingShow = false;
            this.loginShow = false;
        },

        // 注册弹窗关闭
        registerClose() {
            this.registerForm = {
                phone: '',
                smsCode: '',
                username: '',
                type: 2
            };
            this.registerShow = false;
        },

        // 重置密码弹窗关闭
        resettingClose() {
            this.resettingFrom = {
                // 重置密码表单
                phone: '', // 手机号
                password: '', // 密码
                confirmPassword: '', // 重置密码
                smsCode: '' // 短信验证码
            };
            this.resettingShow = false;
        },

        // 重置密码弹窗
        toRese() {
            this.resettingFrom = {
                // 重置密码表单
                phone: this.param.phone, // 手机号
                password: '', // 密码
                confirmPassword: '', // 重置密码
                smsCode: '' // 短信验证码
            };

            this.resettingShow = true;
            this.loginShow = false; // 关闭登录弹窗
        },

        handleScrollBox() {
            if (this.setSolShow) return;

            let offTop = this.$refs.setTimeRef.getBoundingClientRect().top; // 元素距离顶部距离
            let offsetHeight = this.$refs.setTimeRef.offsetHeight; // 元素距离顶部距离
            let offsetHeight1 = this.$refs.setTimeRef1.offsetHeight; // 元素距离顶部距离
            if (Math.abs(offTop) > offsetHeight) {
                // 滚动距离超过banner栏  则高光优势
                this.topIndex = 2;
            }
            if (Math.abs(offTop) > offsetHeight + offsetHeight1) {
                this.topIndex = 3;
            }

            if (Math.abs(offTop) < offsetHeight) {
                this.topIndex = 1;
            }

            // 变量scrollTop是滚动条滚动时，距离顶部的距离
            var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            // 变量windowHeight是可视区的高度
            var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
            // 变量scrollHeight是滚动条的总高度
            var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;

            if (scrollTop + windowHeight === scrollHeight) {
                // console.log(11111)
                this.topIndex = 4;
            }
            sessionStorage.setItem('topIndex', this.topIndex);
            // console.log(this.topIndex, 'offTop');
        },

        setCor(index) {
            // console.log(`corBox${index}`);
            this.setSolShow = true; // 终止监听滚动事件
            this.topIndex = index;
            sessionStorage.setItem('topIndex', index);
            document.getElementById(`corBox${index}`).scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest'
            });

            setTimeout(() => {
                this.setSolShow = false;
            }, 1000);
        },

        getUnionByDomain() {
            let data = { host: window.location.host };
            let this_ = this;
            request.get('/system/union/find', data).then((ret) => {
                if (ret.code === 1) {
                    this_.title = ret.data.list.name;
                    this.$nextTick(() => {
                        this.unionLoginParams.name = ret.data.list.name;
                    });
                }
            });
        },

        // 获取短信验证码  注册
        sendCode() {
            let this_ = this;
            if (this.sendCodeDisabled) {
                return false;
            }
            this.$refs.registerForm.validateField('phone', (errorMessage) => {
                if (errorMessage) {
                    this.$message.error(errorMessage);
                } else {
                    // 1.时间开始倒数
                    // 2.按钮进入禁用状态
                    // 3.如果倒计时结束 按钮恢复可用状态 按钮文字变为重新发送, 时间重置
                    // 4.倒计时的过程中 按钮文字为 多少s后重新发送
                    let params = { phone: this.registerForm.phone, ty: 'zc' };
                    request.post('/common/code', params).then((ret) => {
                        if (ret.code === 1) {
                            this_.title = ret.data.list.name;
                        }
                    });
                    let timer = setInterval(() => {
                        this_.sendCodeDisabled = true;
                        this_.time--;
                        this_.btnText = `${this.time}s后重新发送`;
                        this_.disabled = true;
                        if (this_.time === 0) {
                            this_.disabled = false;
                            this_.btnText = '重新发送';
                            this_.time = 60;
                            clearInterval(timer);
                        }
                    }, 1000);
                }
            });
        },

        // 获取短信验证码  重置密码
        sendCodeReset() {
            if (this.sendCodeDisabledReset) {
                return false;
            }
            this.$refs.resetForm.validateField('phone', (errorMessage) => {
                if (errorMessage) {
                    this.$message.error(errorMessage);
                } else {
                    // 1.时间开始倒数
                    // 2.按钮进入禁用状态
                    // 3.如果倒计时结束 按钮恢复可用状态 按钮文字变为重新发送, 时间重置
                    // 4.倒计时的过程中 按钮文字为 多少s后重新发送
                    let params = { phone: this.resettingFrom.phone, ty: 'zc' };
                    /* request.post("/common/code",params ).then((ret) => {
                      if (ret.code===1){
                          this_.title=ret.data.list.name
                      }
                  })*/
                    let timerR = setInterval(() => {
                        this.sendCodeDisabledReset = true;
                        this.timeReset--;
                        this.resetBtnText = `${this.timeReset}s后重新发送`;
                        this.disabled = true;
                        if (this.timeReset === 0) {
                            this.disabled = false;
                            this.resetBtnText = '重新发送';
                            this.timeReset = 60;
                            clearInterval(timerR);
                        }
                    }, 1000);
                }
            });
        },

        /**
         *
         * @param redirect  是否跳转默认菜单页面
         */
        getAdminRouter(redirect) {
            let that = this;
            request.get('/user/menu/list', []).then(async (ret) => {
                // this.$store.commit('adminRouter/setRouterlist', vuerouter)
                this.$store.commit('adminRouter/setsiderRouter', ret.data.list);

                // this.$store.dispatch('adminRouter/set_Routerlist', vuerouter)
                this.$store.dispatch('adminRouter/set_siderRouter', ret.data.list);

                // 管理员默认的登陆菜单页
                if (redirect === true) {
                    if (ret.data.default_menu) {
                        await that.$router.push(ret.data.default_menu.url);
                    } else {
                        await that.$router.push('/');
                    }
                }
            });
        }
    }
};
</script>

<style scoped lang="scss">
.login-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    background: url('../assets/images/login_bg.jpg') no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: #fff;

    .loginBox {
        position: absolute;
        background: #fff;

        border-radius: 10px;
        padding: 10px 30px;
        height: fit-content;
        width: fit-content;

        .boxTitle {
            font-size: 20px;
            font-weight: bold;
            text-align: center;
            padding: 10px 0;
        }
    }
}

.topTitleBox {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 100;
}

.topTitle {
    width: 75%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.topTitle-L {
    flex: 1;
    font-size: 32px;
    color: #000000;
    padding: 40px 0;
}

.topTitle-R {
    display: flex;
    align-items: center;
    justify-content: center;
    // transition: all 0.5s ease;
}

.setTopTitleSpan {
    font-size: 24px;
    font-weight: 600;
    // transition: all 0.5s ease;
}

.dialogBox {
    width: 70%;
    display: flex;
    // align-items: center;
    flex-direction: column;
    margin: auto;
    position: relative;
    z-index: 2;
    // justify-content: center;
    // padding: 0 40px;

    .dialogBox-title {
        font-size: 24px;
        color: #000;
        padding-left: 30px;
    }
}

.topTitle-R span {
    font-size: 18px;
    margin-right: 40px;
    color: #263238;
    cursor: pointer;
}

.banner {
    width: 100%;
    height: 100vh;
    display: flex;
    background-image: url('https://lihansy.oss-cn-beijing.aliyuncs.com/admin/image/BeMZj804bf7f355df3518a37f469fef44436e50.png');
    background-size: 100%;
    position: relative;

    .bannerMo {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        background-color: #000;
        z-index: 1;
        opacity: 0.8;
    }
    /* padding: 120px 0; */
}

.banner-body {
    width: 75%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 10;
    // padding: 200px 0;
}

.banner-body-l {
    flex: 1;
    /* margin-right: 40px; */
}

.banner-body-l-top {
    font-size: 42px;
    color: #fff;
}

.banner-body-l-cent {
    font-size: 18px;
    width: 75%;
    color: #fff;
    margin-top: 30px;
}

.banner-body-l-but {
    display: flex;
    align-items: center;
    margin-top: 60px;
    /* justify-content: center; */
}

.body-l-but {
    width: 150px;
    height: 50px;
    border-radius: 50px;
    font-size: 18px;
    margin: 0 20px;
}

.banner-body-r {
    width: 670px;
}

.superiority {
    width: 100%;
    padding: 100px 0;

    .title {
        font-size: 48px;
        color: #000;
        text-align: center;
        position: relative;

        &::before {
            position: absolute;
            content: '';
            left: 0;
            right: 0;
            margin: auto;
            bottom: -25px;
            width: 200px;
            height: 4px;
            background-color: #b4b6b8;
        }

        &::after {
            position: absolute;
            content: '1000家品牌优质供应链加持，助力流量主 高效变现';
            left: 0;
            right: 0;
            margin: auto;
            bottom: -100px;
            font-size: 24px;
            color: #9e9e9e;
        }
    }

    .superiority-body {
        width: 80%;
        margin: auto;
        display: flex;
        margin-top: 220px;

        .superiority-b-item {
            width: 260px;
            box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.16);
            padding: 30px 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 15px;
            // justify-content: center;

            & img {
                width: 90px;
                height: 90px;
            }

            .superiority-b-item-title {
                font-size: 24px;
                margin-top: 40px;
                color: #000000;
            }

            .superiority-b-item-label {
                font-size: 18px;
                color: #9e9e9e;
                text-align: center;
                margin-top: 20px;
            }
        }
    }
}

.cooperate {
    width: 100%;
    padding: 100px 0;
    background-color: #f4f6f8;

    .title {
        font-size: 48px;
        color: #000;
        text-align: center;
        position: relative;

        &::before {
            position: absolute;
            content: '';
            left: 0;
            right: 0;
            margin: auto;
            bottom: -25px;
            width: 200px;
            height: 4px;
            background-color: #b4b6b8;
        }

        &::after {
            position: absolute;
            content: '向全球招募优质合作商';
            left: 0;
            right: 0;
            margin: auto;
            bottom: -100px;
            font-size: 24px;
            color: #9e9e9e;
        }
    }

    .cooperate-body {
        width: 80%;
        margin: auto;
        margin-top: 220px;

        .cooperate-body-centBox {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            .centBox-imgBox {
                width: 320px;
                height: 640px;
                background-image: url('../assets/images/cooperate-icon.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;
                position: relative;
                z-index: 11;
            }

            .centBox-imgBox2 {
                width: 300px;
                height: 600px;
                position: absolute;
                background-image: url('../assets/images/cooperate-icon.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;
                z-index: 10;
            }

            .centBox-imgBox2L {
                left: 22%;
            }

            .centBox-imgBox1L {
                left: 7%;
            }

            .centBox-imgBox2R {
                right: 22%;
            }

            .centBox-imgBox1R {
                right: 7%;
            }

            .centBox-imgBox1 {
                width: 260px;
                height: 520px;
                position: absolute;
                background-image: url('../assets/images/cooperate-icon.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;
                z-index: 9;
            }
        }

        .cooperate-body-number {
            width: 100%;
            display: flex;
            background-color: #fff;
            padding: 40px 20px;
            margin-top: 80px;

            .cooperate-body-number-item {
                flex: 1;
                display: flex;
                align-items: center;
                flex-direction: column;
                font-size: 24px;
                color: #9e9e9e;

                & span {
                    font-size: 40px;
                    color: #000;
                    margin-bottom: 10px;
                }
            }

            .set-number-item-bor {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                &::before {
                    content: '';
                    width: 1px;
                    height: 50%;
                    background-color: #d6e3ed;
                    position: absolute;
                    right: 0;
                }
            }
        }
    }
}

.contact {
    width: 100%;
    background-color: #145fea;
    padding: 80px 0;
    padding-bottom: 120px;
    position: relative;
    overflow: hidden;

    .contact-PrImg {
        position: absolute;
        width: 480px;
        height: 480px;
        border-radius: 100%;
        background: rgba(255, 255, 255, 0.1);
        right: 4%;
        top: -85%;
    }

    .contact-PrImg1 {
        position: absolute;
        width: 480px;
        height: 480px;
        border-radius: 100%;
        background: rgba(255, 255, 255, 0.1);
        left: 4%;
        bottom: -105%;
    }

    .contact-PrImg2 {
        position: absolute;
        width: 350px;
        height: 350px;
        border-radius: 100%;
        background: rgba(255, 255, 255, 0.1);
        left: 20%;
        bottom: -80%;
    }

    .contact-box {
        width: 70%;
        margin: auto;
        display: flex;

        .contact-box-l {
            padding-right: 40px;
            color: #fff;
            font-size: 36px;
            position: relative;
            // display: flex;
            // align-items: center;
            // flex-direction: column;

            // &::before {
            //     content: '';
            //     position: absolute;
            //     right: 0;
            //     width: 1px;
            //     height: 50%;
            //     background-color: #d8d8d8;
            // }
        }

        .contact-box-r {
            margin-left: 100px;
            flex: 1;
            display: flex;
            align-items: center;
            padding-left: 100px;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                width: 1px;
                height: 80%;
                background-color: #d8d8d8;
            }
            // justify-content: c;
            .contact-box-r-l {
                flex: 1;
                .contactInfo {
                    margin-top: 20px;
                    color: #fff;
                    font-size: 18px;
                }
            }

            .contact-box-r-r {
                display: flex;
                // margin-left: 220px;
                font-size: 18px;
                color: #fff;
                text-align: center;

                .URcodeBox {
                    width: 130px;
                    height: 130px;
                    background-color: #d8d8d8;
                    margin-bottom: 10px;
                    border-radius: 10px;
                }
            }
        }
    }
}

.tips {
    width: 80%;
    margin: auto;
    font-size: 14px;
    color: #9e9e9e;
    padding: 20px 0;
    display: flex;
    .tips-l {
        flex: 1;
        display: flex;
        align-items: center;
        & img {
            width: 14px;
            height: auto;
            margin-right: 10px;
        }
    }
    .tips-r {
        cursor: pointer;
    }
}

.ms-title {
    width: 100%;
    line-height: 50px;
    text-align: center;
    font-size: 20px;
    color: #aaa;
    border-bottom: 1px solid #ddd;
}
.nav-group {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 350px;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    opacity: 0.9;
    overflow: hidden;
}

.distributor-register {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 350px;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    background: #ffffff;
    opacity: 0.9;
    overflow: hidden;
}

.ms-content {
    padding: 30px 30px;
}
// .input {
//     // width: 100% !important;
//     border-color: #e3e5e5 !important;
//     height: 40px;
// }
.login-btn {
    text-align: center;
}

.login-btn button {
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
    border-radius: 30px;
    font-size: 18px;
    margin-top: 20px;
    background: linear-gradient(135deg, #3f52ee 0%, #0e20ad 100%);
}

.login-tips {
    font-size: 12px;
    // line-height: 30px;
    color: #000;
    width: 100%;
    text-align: right;
    margin-top: 10px;

    & span {
        cursor: pointer;
        color: #4791ff;
    }
}

.loginLBox-item {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;

    .loginLBox-item-icon {
        width: 160px;
        height: 160px;
        border-radius: 100%;
        position: absolute;
        background: rgba(255, 255, 255, 0.1);
        right: -110px;
        top: 0;
    }

    .loginLBox-item-icon1 {
        width: 100px;
        height: 100px;
        border-radius: 100%;
        position: absolute;
        background: rgba(255, 255, 255, 0.1);
        left: -80px;
        bottom: 110px;
    }

    .loginLBox-item-icon2 {
        width: 160px;
        height: 160px;
        border-radius: 100%;
        position: absolute;
        background: rgba(255, 255, 255, 0.1);
        left: -110px;
        bottom: 0;
    }
}
</style>
<style scoped>
/deep/.setDiaLog .el-dialog {
    overflow: hidden;
    border-radius: 20px !important;
}

.setLoginImg {
    position: absolute;
    bottom: 0;
    left: 90px;
    width: 140px;
    z-index: 1;
}

.loginLBox {
    width: 90px;
    height: 100%;
    background: #0e20ad;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
}

.SetInput {
    width: 100% !important;
}

/deep/ .SetInput .el-input__inner {
    height: 40px;
}

/deep/ .input .el-input__inner {
    height: 40px;
}

/deep/#pass {
    border: none !important;
}
</style>
